import { Button } from "@material-ui/core";
import {
  CheckCircle,
  EmailOutlined,
  LocationOnOutlined,
  PhoneAndroidOutlined,
  SvgIconComponent,
} from "@material-ui/icons";
import { graphql, Link } from "gatsby";
import {
  StaticImage,
  GatsbyImage,
  IGatsbyImageData,
} from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactForm from "../components/ui/Contact/ContactForm/ContactForm";
import ContactInfoItem from "../components/ui/Contact/ContactInfo/ContactInfoItem";
import ProjectGallery from "../components/ui/ProjectGallery/ProjectGallery";
import SectionIntro from "../components/ui/SectionIntro/SectionIntro";
import { ContactInfo } from "../fragments/contactInfoFields";
import { ServicePage } from "../fragments/servicePageFields";
import { Images } from "../utils/findImage";
import { formatPhoneNumber } from "../utils/formatPhoneNumber";

interface ServiceLandingPageTemplateProps {
  pageContext: {};
  data: {
    prismicService: ServicePage;
    projectGallery: Images;
    prismicContactInfo: ContactInfo;
  };
  location: Location;
}

const ServiceLandingPageTemplate: React.FC<ServiceLandingPageTemplateProps> = ({
  pageContext,
  data,
  location,
}) => {
  const serviceData = data.prismicService.data;
  const contactInfo = data.prismicContactInfo.data;

  return (
    <Layout>
      <SEO
        title={serviceData.seo[0].seo_title}
        description={serviceData.seo[0].seo_description}
        slug={location.pathname}
      />

      <section className="bg-gray-800">
        <div className="container">
          <h1 className="text-3xl lg:text-4xl text-white leading-none mb-0">
            {serviceData.seo[0].seo_title}
          </h1>
        </div>
      </section>

      {serviceData.body.map((slice) => {
        if (slice.__typename === "PrismicServiceBodyServiceDescription") {
          return (
            <section key={slice.__typename}>
              <div className="container">
                <div className="grid grid-cols-1 items-center">
                  <div className="justify-self-center ">
                    <h2 className="text-2xl lg:text-3xl text-center mb-8">
                      {slice.primary.heading.text}
                    </h2>
                  </div>
                </div>
                {slice.items.map((item, index) => (
                  <div
                    key={`${slice.__typename}-item-${index + 1}`}
                    className="grid grid-cols-1 lg:grid-cols-2 gap-x-12 mb-8 last:mb-0 lg:mb-16 xl:items-center max-w-2xl mx-auto lg:max-w-none"
                  >
                    <div className="max-w-xl mx-auto lg:mx-0">
                      {item.description.raw.map(({ text }, textIndex) => (
                        <p
                          key={`${slice.__typename}-text-${textIndex + 1}`}
                          className="mb-4 last:mb-0"
                        >
                          {text}
                        </p>
                      ))}
                    </div>
                    <div
                      className={
                        item.image_position === "left"
                          ? "order-first mb-4 lg:mb-0"
                          : "order-first mb-4 lg:mb-0 lg:order-none"
                      }
                    >
                      {!!item.image ? (
                        <GatsbyImage
                          image={
                            item.image.localFile.childImageSharp.gatsbyImageData
                          }
                          alt={item.image.alt || ""}
                        />
                      ) : (
                        <StaticImage
                          src="https://via.placeholder.com/600x500/333/?text=%20"
                          alt="Image"
                          layout="fullWidth"
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          );
        } else if (slice.__typename === "PrismicServiceBodyCta") {
          return (
            <section key={slice.__typename} className="bg-gray-900 py-20">
              <div className="container">
                <div className="flex flex-wrap md:justify-between justify-center items-center">
                  <h3 className="text-2xl text-white md:mb-0 text-center sm:text-left mb-4">
                    {slice.primary.headine.text}
                  </h3>
                  <Button
                    component={Link}
                    to="/contact/"
                    className="rounded-none font-semibold text-center"
                    variant="contained"
                    color="primary"
                    size="large"
                    disableElevation
                  >
                    {slice.primary.button_text}
                  </Button>
                </div>
              </div>
            </section>
          );
        } else if (slice.__typename === "PrismicServiceBodyServiceExpertise") {
          return (
            <section key={slice.__typename} className="bg-gray-50">
              <div className="container">
                <div className="flex flex-wrap justify-between -mb-8">
                  <SectionIntro
                    subHeading={slice.primary.section_intro_sub_heading.text}
                    heading={slice.primary.section_intro_heading.text}
                    className="w-full mb-4"
                  />
                  {slice.items.map((item, index) => (
                    <ServiceExpertiseItem
                      key={`${slice.__typename}-item-${index + 1}`}
                      icon={CheckCircle}
                      title={item.related_service_name}
                    >
                      {item.related_service_description.text}
                    </ServiceExpertiseItem>
                  ))}
                </div>
              </div>
            </section>
          );
        } else if (slice.__typename === "PrismicServiceBodyProjectsGallery") {
          return (
            <section key={slice.__typename}>
              <div className="container">
                <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 ">
                  <SectionIntro
                    subHeading={slice.primary.section_intro_sub_heading.text}
                    heading={slice.primary.section_intro_heading.text}
                    className="col-span-full text-center mb-6 justify-self-center"
                  >
                    <p className="max-w-lg mx-auto">
                      {slice.primary.section_intro_description.text}
                    </p>
                  </SectionIntro>
                  <ProjectGallery>
                    {slice.items.map((item, index) => (
                      <div key={`portfolio item-${index}`} className="mb-4">
                        <GatsbyImage
                          image={
                            item.image.localFile.childImageSharp.gatsbyImageData
                          }
                          alt={item.image.alt || ""}
                        />
                      </div>
                    ))}
                  </ProjectGallery>
                </div>
              </div>
            </section>
          );
        } else return null;
      })}

      <section className="bg-gray-50">
        <div className="container">
          <div className="flex flex-wrap justify-between">
            <div className="sm:w-4/12 w-full mb-6 sm:mb-0">
              <h3 className="sm:text-3xl text-2xl">Contact Us</h3>

              <ul>
                <ContactInfoItem icon={LocationOnOutlined} title="Address">
                  <li>{contactInfo.address[0].street}</li>
                  <li>
                    <span className="mr-1">{contactInfo.address[0].city}</span>
                    <span>{contactInfo.address[0].zip_code}</span>
                  </li>
                  <li>{contactInfo.address[0].state}</li>
                </ContactInfoItem>
                <ContactInfoItem icon={EmailOutlined} title="Email">
                  <li>
                    <a
                      className="hover:text-primary-main"
                      href={`mailto:${contactInfo.email}`}
                    >
                      {contactInfo.email}
                    </a>
                  </li>
                </ContactInfoItem>
                <ContactInfoItem icon={PhoneAndroidOutlined} title="Phone">
                  <li>
                    <a
                      className="hover:text-primary-main"
                      href={`tel:${contactInfo.phone_number}`}
                    >
                      {formatPhoneNumber(contactInfo.phone_number)}
                    </a>
                  </li>
                </ContactInfoItem>
              </ul>
            </div>
            <div className="lg:w-5/12 sm:w-6/12 w-full">
              <h3 className="sm:text-3xl text-2xl">Send us a message</h3>
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ServiceLandingPageTemplate;

export const pageQuery = graphql`
  query ServicePage($id: String!) {
    prismicService(id: { eq: $id }) {
      id
      prismicId
      data {
        service_name {
          text
        }
        seo {
          seo_title
          seo_description
        }
        body {
          __typename
          ... on PrismicServiceBodyServiceDescription {
            ...serviceDescriptionFields
          }
          ... on PrismicServiceBodyCta {
            ...serviceCtaFields
          }
          ... on PrismicServiceBodyServiceExpertise {
            ...serviceExpertiseFields
          }
          ... on PrismicServiceBodyProjectsGallery {
            ...serviceProjectsGalleryFields
          }
        }
      }
    }
    prismicContactInfo {
      ...contactInfoFields
    }
    projectGallery: allFile(filter: { base: { regex: "/portfolio/" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
        }
      }
    }
  }
`;

interface ServiceExpertiseItemProps {
  icon: SvgIconComponent;
  title: string;
}

const ServiceExpertiseItem: React.FC<ServiceExpertiseItemProps> = ({
  icon: Icon,
  title,
  children,
}) => {
  return (
    <div className="flex mb-8 max-w-lg md:w-5/12">
      <Icon color="primary" className="text-2xl sm:text-3xl" />
      <ul className="ml-3">
        <li className="sm:text-lg text-base text-text-heading font-medium mb-1">
          {title}
        </li>
        {children}
      </ul>
    </div>
  );
};
